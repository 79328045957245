import React, {useState} from "react";
import styles from './Content.module.scss';
import {useSelector} from "react-redux";
import {getAcToken, getPictureSrc, getWallets} from "../../../store/selectors";
import {Link} from "react-router-dom";
import arrow from '../../../assets/icons/arrowDown.svg'

const Content = ({event, setIsShow}) => {
    const list = useSelector(getWallets);
    const pictureSrc = getPictureSrc;

    function isInteger(num) {
        return (num ^ 0) === num;
    }

    return (
        <ul className={`${styles.content} bgMain`}>
            {list.length > 0 && (
                list.map((item, index) => (
                    <li key={index}>
                        <Link to={'/wallet/' + item.id} className={styles.content__item}>
                            <div className={styles.content__itemCurrency}>
                                {list.length > 0 && (
                                    <img src={`${pictureSrc}${item.icon}`} alt=""/>
                                )}
                                <p>{item.name}</p>
                            </div>
                            {/*<p>{item.finalBalance}</p>*/}
                            <p>{isInteger(Number(item.finalBalance)) ? Number(item.finalBalance).toFixed(2) : item.finalBalance}</p>
                        </Link>
                        {event && event.token === item.name && (
                            <div className={styles.event}>
                                <span className={styles.decorLine}/>
                                <div className={styles.event__info}>
                                    <p>{event.isDeposit ? `Depositing: ${event.deposit_usdt}$` : `Withdrawal: ${event.deposit_usdt}$`}</p>
                                    <button className={'action'} onClick={() => setIsShow(true)}>
                                        <img src={arrow} alt=""/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </li>
                ))
            )}
        </ul>
    )
}

export default Content;