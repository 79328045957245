import React from "react";
import styles from './BaseList.module.scss';
import {getPictureSrc} from "../../../store/selectors";


const BaseList = ({list, setSelectItem}) => {
    const pictureSrc = getPictureSrc;

    function isInteger(num) {
        return (num ^ 0) === num;
    }

    return (
        <ul className={styles.managerList}>
            {list && (
                list.map((item, index) => (
                    <li key={index} className={styles.item} onClick={() => setSelectItem(item)}>
                        <div className={styles.item__name}>
                            <img src={`${pictureSrc}${item.icon}`} alt=""/>
                            <p>{item.name}</p>
                        </div>
                        <div className={styles.item__value}>
                            <p>{isInteger(Number(item.finalBalance)) ? Number(item.finalBalance).toFixed(2) : item.finalBalance} {item.name}</p>
                            <span>{isInteger(Number(item.finalBalance_in_usdt)) ? Number(item.finalBalance_in_usdt).toFixed(2) : item.finalBalance_in_usdt} USD</span>
                        </div>
                    </li>
                ))
            )}
        </ul>
    )
}

export default BaseList;