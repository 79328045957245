import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getBalance, getText} from "../../../store/selectors";
import styles from './Balance.module.scss';
import { CountUp } from 'use-count-up';
import usdtIcon from '../../../assets/icons/usdtMain.png'


const Balance = () => {
    const [currentBalance, setCurrentBalance] = useState(null)
    const balance = useSelector(getBalance);
    const currency = 'USDT';

    function isInteger(num) {
        return (num ^ 0) === num;
    }

    useEffect(() => {
        if (balance > 0) {
            const newBalance = isInteger(Number(balance)) ? Number(balance.toFixed(2)) : balance
            setCurrentBalance(newBalance);
        }
    }, [balance])

    return (
        <div className={styles.balanceWrapper}>
            <div className={styles.balance}>
                {currentBalance
                    ? <h2><img src={usdtIcon} alt=""/> <CountUp decimalPlaces={2} isCounting end={currentBalance} duration={0.3} /></h2>
                    : <h2><img src={usdtIcon} alt=""/> 0.00</h2>
                }
                <span className={`${styles.currency} currency`}>{currency}</span>
            </div>
        </div>
    )
}

export default Balance;