import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getCurrentToken, getNetworkWallets, getText} from "../../store/selectors";
import styles from './Deposit.module.scss';
import {PagesHeader} from "../Common/PagesHeader/PagesHeader";
import DepositNetwork from "./DepositNetwork/DepositNetwork";
import DepositPopupQR from "../Common/DepositPopupQR/DepositPopupQR";
import {useAppSelector} from "../../hooks/redux";
import CreateWalletPopup from "../Common/CreateWalletPopup/CreateWalletPopup";

const Deposit = () => {
    const network = useSelector(getCurrentToken);
    const {wallet} = useSelector(getText);
    const [networkAddress, setNetworkAddress] = useState(null)
    const networks = useAppSelector(getNetworkWallets);
    const [currentNetwork, setCurrentNetwork] = useState(null);
    const [showCreateWallet, setShowCreateWallet] = useState(false);



    useEffect(() => {
        if (network.network && networks) {
            switch (network.network) {
                case 'erc20' :
                    setCurrentNetwork([...networks.erc20]);
                    break;
                case 'trc20' :
                    setCurrentNetwork([...networks.trc20]);
                    break;
                case 'ltc' :
                    setCurrentNetwork([...networks.ltc]);
                    break;
                case 'btc' :
                    setCurrentNetwork([...networks.btc]);
                    break;
                case 'bsc' :
                    setCurrentNetwork([...networks.bsc]);
                    break;
                case 'trx20' :
                    setCurrentNetwork([...networks.trx20]);
                    break;
                default:
                    break;
            }
        }
    }, [networks])

    return (
        <div className={`${styles.deposit} bgMain`}>
            <PagesHeader headerText={wallet.deposit} menu/>
            <ul>
                {currentNetwork && (
                    currentNetwork.map((item, index) =>
                        <DepositNetwork
                            token={network.token.name}
                            icon={network.token.icon}
                            setNetworkAddress={setNetworkAddress}
                            item={item}
                            key={index}
                            index={index + 1}/>
                    )
                )}
            </ul>
            <DepositPopupQR text={wallet} networkAddress={networkAddress} setNetworkAddress={setNetworkAddress}/>
            <button className={`${styles.action} action`} onClick={() => setShowCreateWallet(true)}>
                <span>{wallet.requestAddress}</span>
            </button>
            {network && showCreateWallet && network.network && (
                <CreateWalletPopup
                    network={network.network}
                    text={wallet}
                    setShowCreateWallet={setShowCreateWallet}
                />
            )}
        </div>
    )
}

export default Deposit;