import React, {useEffect, useState} from "react";
import styles from './WithdrawWallet.module.scss';
import {SearchFull} from "../../Common/SearchFull/SearchFull";
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import {useSelector} from "react-redux";
import {getText, getWallets} from "../../../store/selectors";
import BaseList from "../../Common/BaseList/BaseList";
import WithdrawDepositPopup from "../WithdrawDepositPopup/WithdrawDepositPopup";

const WithdrawWallet = () => {
    const {wallet} = useSelector(getText);
    const balances = useSelector(getWallets);
    const [selectItem, setSelectItem] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (balances && searchValue === '') {
            setWalletList(balances)
        } else if (balances) {
            const result = balances
                .filter(prof => {
                    return prof.name.toLowerCase().includes(searchValue.toLowerCase());
                })
                .sort((a, b) => {
                    if(a.name.toLowerCase().indexOf(searchValue.toLowerCase()) > b.name.toLowerCase().indexOf(searchValue.toLowerCase())) {
                        return 1;
                    } else if (a.name.toLowerCase().indexOf(searchValue.toLowerCase()) < b.name.toLowerCase().indexOf(searchValue.toLowerCase())) {
                        return -1;
                    } else {
                        if(a.name > b.name)
                            return 1;
                        else
                            return -1;
                    }
                });
            setWalletList(result);
        }
    }, [balances, searchValue]);

    const networkPopup = (item) => {
        setSelectItem(item);
        setShowPopup(true);
    }

    return (
        <div className={`${styles.withdrawWallet} bgMain`}>
            <PagesHeader headerText={wallet.withdraw} menu />
            <SearchFull placeholder={'Search assets'} value={searchValue} setValue={setSearchValue} />
            <div className={styles.list}>
                <BaseList setSelectItem={networkPopup} list={walletList} />
            </div>
            <WithdrawDepositPopup withdraw showPopup={showPopup} item={selectItem} setShowPopup={setShowPopup} />
        </div>
    )
}

export default WithdrawWallet;